<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span
            class="logo-mini"
            :class="{'mt-3': hostname.includes('hypertex')}"
          >
            {{ $t('ct') }}
          </span>
          <span class="logo-normal">
            <v-img
              style="margin-top: 4px"
              max-width="60%"
              :src="require(hostname.includes('hypertex') ? '@/assets/logo_hypertex.png' : '@/assets/logo_lorenzi.png')"
            />
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <!-- <v-list
      dense
      nav
    >
      <base-item-group :item="profile" />
    </v-list>

    <v-divider class="mb-2" /> -->

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
          @click="run(item.click)"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <v-dialog
      v-model="cameraDialog"
      max-width="500"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card class="text-center">
        <v-card-title>
          Scan new product
          <v-spacer />

          <v-icon
            aria-label="Close"
            @click="cameraDialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <qrcode-stream @decode="onQrcodeDecode" />
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="error"
            text
            @click="cameraDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-layers-search',
          title: 'Search Products',
          to: '/',
        },
        {
          icon: 'mdi-clipboard-list',
          title: 'Products List',
          to: '/products-list',

        },
        {
          icon: 'mdi-qrcode',
          title: 'Scan Product',
          // eslint-disable-next-line no-console
          click: 'openCameraDialog',
        },
        // {
        //   icon: 'mdi-calendar-range',
        //   title: 'Appointment',
        //   to: '/calendar',
        // },
      ],
      hostname: location.hostname,
    }),

    computed: {
      ...mapState(['barColor', 'barImage', 'cameraDialog']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      cameraDialog: {
        get () {
          return this.$store.state.cameraDialog
        },
        set (val) {
          this.$store.commit('SET_CAMERA_DIALOG', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      profile () {
        return {
          avatar: false,
          group: '',
          title: this.$t('avatar'),
          children: [
            {
              to: 'profile',
              title: this.$t('my-profile'),
            },
            {
              to: 'logout',
              title: this.$t('logout'),
            },
          ],
        }
      },
    },

    watch: {
      '$vuetify.breakpoint.smAndDown' (val) {
        this.$emit('update:expandOnHover', !val)
      },
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      run (event) {
        if (event) {
          this[event]()
        }
      },
      openCameraDialog () {
        this.cameraDialog = true
      },
      onQrcodeDecode (decodedString) {
        const productRegExp = new RegExp('(.*.lorenzinet.com|localhost|192.168.*)/products/.*')
        if (productRegExp.test(decodedString)) {
          this.cameraDialog = false
          window.location.href = decodedString
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
